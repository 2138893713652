import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Reports from "../views/Reports.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "reports",
		component: Reports,
		meta: {
			roles: ["admin", "gas-admin"]
		}
	},
	{
		path: "/products",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "products" */ "../views/products/Products.vue"),
		children: [
			{
				path: "/",
				name: "products",
				component: () => import(/* webpackChunkName: "products-main" */ "../views/products/ProductsMain.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "add",
				component: () => import(/* webpackChunkName: "products-add" */ "../views/products/AddProduct.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "edit",
				component: () => import(/* webpackChunkName: "products-edit" */ "../views/products/EditProduct.vue"),
				meta: {
					roles: ["admin"]
				}
			}
		]
	},
	{
		path: "/ingredients",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "ingredients" */ "../views/ingredients/Ingredients.vue"),
		children: [
			{
				path: "/",
				name: "ingredients",
				component: () => import(/* webpackChunkName: "ingredients-main" */ "../views/ingredients/IngredientsMain.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "add",
				component: () => import(/* webpackChunkName: "ingredients-add" */ "../views/ingredients/AddIngredient.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "edit",
				component: () => import(/* webpackChunkName: "ingredients-edit" */ "../views/ingredients/EditIngredient.vue"),
				meta: {
					roles: ["admin"]
				}
			}
		]
	},
	{
		path: "/allergens",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "allergens" */ "../views/allergens/Allergens.vue"),
		children: [
			{
				path: "/",
				name: "allergens",
				component: () => import(/* webpackChunkName: "allergens-main" */ "../views/allergens/AllergensMain.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "add",
				component: () => import(/* webpackChunkName: "allergens-add" */ "../views/allergens/AddAllergen.vue"),
				meta: {
					roles: ["admin"]
				}
			},
			{
				path: "edit",
				component: () => import(/* webpackChunkName: "allergens-edit" */ "../views/allergens/EditAllergen.vue"),
				meta: {
					roles: ["admin"]
				}
			}
		]
	},
	{
		path: "/tablets",
		name: "tablets",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "tablets" */ "../views/Tablets.vue"),
		meta: {
			roles: ["sys-admin", "admin"]
		}
	},
	{
		path: "/users",
		name: "users",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "users" */ "../views/Users.vue"),
		meta: {
			roles: ["sys-admin"]
		}
	},
	{
		path: "/operators",
		name: "operators",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "operators" */ "../views/Operators.vue"),
		meta: {
			roles: ["gas-admin"]
		}
	},
	{
		path: "/demand",
		name: "Projected Demand",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "reports" */ "../views/Demand.vue"),
		meta: {
			roles: ["sys-admin", "admin", "gas-admin"]
		}
	},
	{
		path: "/haacp",
		name: "haacp",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "reports" */ "../views/HAACP.vue"),
		meta: {
			roles: ["sys-admin", "admin", "gas-admin"]
		}
	},
	{
		path: "/monitoring",
		name: "monitoring",
		component: () => import(/* webpackChunkName: "monitoring" */ "../views/Monitoring.vue"),
		meta: {
			roles: ["admin", "gas-admin"]
		}
	},
	{
		path: "/stations",
		name: "stations",
		component: () => import(/* webpackChunkName: "zhour" */ "../views/Stations.vue"),
		meta: {
			roles: ["sys-admin", "admin", "gas-admin"]
		}
	},
	{
		path: "/traceability",
		name: "traceability",
		component: () => import(/* webpackChunkName: "zhour" */ "../views/Traceability.vue"),
		meta: {
			roles: ["sys-admin", "admin"]
		}
	},
	{
		path: "/settings",
		name: "settings",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
		meta: {
			roles: ["sys-admin", "admin", "gas-admin"]
		}
	},
	{
		path: "/standard-times",
		name: "standard-times",
		component: () => import(/* webpackChunkName: "standard-times" */ "../views/StandardTimes.vue"),
		meta: {
			roles: ["sys-admin", "admin"]
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (to.meta?.roles) {
		if (!to.meta.roles.includes(localStorage.getItem("role")) && localStorage.getItem("role")) {
			if (localStorage.getItem("role") === "sys-admin") {
				next({
					path: "/users"
				});
			} else {
				next({
					path: "/"
				});
			}
		} else {
			next();
		}
	} else {
		next(); // make sure to always call next()!
	}
});

export default router;
